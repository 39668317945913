<script setup lang="ts">
import { CheckEmailprops } from "./CheckEmail.props"

const emit =
  defineEmits<{
    (e: "onCancel"): void
    (e: "onConfirm"): void
  }>()

const handleCancel = () => {
  emit("onCancel")
}

const handleConfirm = () => {
  emit("onConfirm")
}

const props = withDefaults(defineProps<CheckEmailprops>(), {
  title: "",
  email: "",
  text: "",
  buttonCancel: "",
  buttonSuccess: "",
  isButtonCancelLoading: false,
  isButtonSuccessLoading: false
})

const { t } = useI18n()
const titleLabel =
  props.title || t("organism.cart.modals.passwordForget.modalTitle")
const emailWithText = computed(() =>
  t("dialogsAndModals.checkEmail.description", {
    email: props.email,
    text: props.text || t("organism.cart.modals.passwordForget.modalMessage")
  })
)
const buttonCancelLabel =
  props.buttonCancel || t("organism.cart.modals.passwordForget.buttonCancel")
const buttonSuccessLabel =
  props.buttonSuccess || t("organism.cart.modals.passwordForget.buttonSuccess")
</script>

<template>
  <div
    class="
      fixed
      inset-0
      z-[999999]
      flex
      w-screen
      items-center
      justify-center
      bg-black-800/20
      px-4
      opacity-100
      md:px-0
    "
    id="headlessui-popover-overlay-:r1m:"
    aria-hidden="true"
    data-headlessui-state="open"
  >
    <div
      class="
        flex
        w-full
        min-w-[300px]
        flex-shrink-0 flex-col
        items-center
        rounded-lg
        bg-white
        px-4
        pb-4
        pt-6
        shadow-01
        md:w-[470px]
      "
    >
      <p v-if="titleLabel" class="hyppo-bold mb-4 w-full text-center md:px-6">
        {{ titleLabel }}
      </p>

      <UtilsMarkdown
        v-if="emailWithText"
        class="beaver md:pig my-4 max-w-full px-6 text-center"
        :content="emailWithText"
      />

      <UtilsButton
        v-if="buttonCancelLabel"
        @click="handleCancel"
        :text="buttonCancelLabel"
        class="my-2 w-full md:px-6"
        :is-loading="isButtonCancelLoading"
      >
        <template #icon>
          <UtilsLoader v-if="isButtonCancelLoading" class="mr-2"
        /></template>
      </UtilsButton>

      <UtilsButton
        v-if="buttonSuccessLabel"
        @click="handleConfirm"
        :text="buttonSuccessLabel"
        theme="outlined-green"
        class="w-full md:px-6"
        :is-loading="isButtonSuccessLoading"
      >
        <template #icon>
          <UtilsLoader v-if="isButtonSuccessLoading" class="mr-2"
        /></template>
      </UtilsButton>
    </div>
  </div>
</template>
